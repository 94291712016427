body {
	#sidebar {
		background-color: #fff;
		width: 100%;
		height: 100vh;

		.nav-pills {
			.sidebar-item {
				width: 100%;
				max-width: 220px;
				min-width: 220px;

				.mini-sidebar-text {
					min-width: 180px;
				}

				.mini-sidebar-text,
				.hide-menu {
					text-wrap: wrap;
				}

				.mini-sidebar-item {
					width: 100%;

					.mini-sidebar-text {
						max-width: 120px;
						width: 100%;
					}

					.dots {
						width: 4px;
						height: 4px;
						border-radius: 50%;
						background-color: rgba(var(--bs-link-color-rgb));
					}
				}

				.mini-sidebar-link {
					.round-16 {
						margin: 0 9px;
					}

					&.active,
					&:hover {
						.dots {
							background-color: var(--bs-primary);
						}

						.mini-sidebar-text {
							color: var(--bs-primary);
						}
					}
				}
			}
		}
	}

	#wrapper-page {
		min-width: 1000px;

		.page-header {
			background-color: var(--bs-primary-bg-subtle);

			.title {
				margin-bottom: 0;
			}

			.text {
				padding-top: 4px;
				margin-bottom: 0;
			}

			.breadcrumbs {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 24px;
				padding-top: 4px;

				p {
					margin: 0;
					padding: 0;
				}

				p.dots {
					position: relative;

					&::before {
						content: "";
						position: absolute;
						background-color: var(--bs-body-color);
						border-radius: 50%;
						width: 4px;
						height: 4px;
						top: 52%;
						left: -14px;
						transform: translateY(-50%);
					}
				}
			}
		}

		// width: calc(100% - 280px);
		background-color: #fdfdfd;
	}

	#header-common {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 12px 0 18px;

		.side {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 8px;
		}

		.ti {
			font-size: 30px;
		}

		.ti-world {
			cursor: pointer;
			font-size: 24px;
		}
	}
}

#customer-notes {
	.wrap-notes {
		max-height: 438px;
	}

	.card-body {
		height: 100%;

		.note-content {
			max-height: 134px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			line-clamp: 5;
			-webkit-box-orient: vertical;
			margin-bottom: -24px;
		}
	}
}

// CSS for <TableList />
.wrap-table-list {
	.table > :not(caption) > * > * {
		line-height: 34px;
	}
}

// Css for <TextStatus /> component
.textStatus {
	font-size: 12px;
	line-height: 16.8px;
	font-weight: 600;
}

.applicationApplied {
	color: var(--bs-primary);
	background-color: var(--bs-success-bg-subtle);
}

.terminationRequested {
	color: var(--bs-red-danger);
	background-color: var(--bs-red-danger-low);
}

.applicationRejected {
	color: var(--bs-gray-500);
	background-color: var(--bs-gray-200);
}

.terminationRejected {
	color: var(--bs-gray-500);
	background-color: var(--bs-gray-200);
}

.applicationApproved {
	color: var(--bs-white);
	background-color: var(--bs-cyan);
}

.terminationCompleted {
	color: var(--bs-white);
	background-color: var(--bs-gray-400);
}

.contractInProgress {
	color: var(--bs-blue-light);
	background-color: var(--bs-primary-bg-subtle);
}

.expired {
	color: var(--bs-danger);
	background-color: var(--bs-danger-bg-subtle);
}

.cancellationRequested {
	color: var(--bs-yellow);
	background-color: var(--bs-warning-bg-subtle);
}

.cancellationRejected {
	color: var(--bs-gray-500);
	background-color: var(--bs-gray-200);
}

.cancellationCompleted {
	color: var(--bs-white);
	background-color: var(--bs-gray-400);
}

.min-w-100 {
	min-width: 100px;
}

.min-w-150 {
	min-width: 150px;
}

.min-w-200 {
	min-width: 200px;
}

.min-w-400 {
	min-width: 400px;
}

.max-w-100 {
	max-width: 100px;
}

.max-w-150 {
	max-width: 150px;
}

.max-w-200 {
	max-width: 200px;
}

.max-w-400 {
	max-width: 400px;
}

.max-w-1000 {
	max-width: 1000px;
}

// SweetAlert2 Custom
.swal2-cancel,
.swal2-deny,
.swal2-confirm {
	min-width: 120px;
}

.kare-input {
	position: relative;

	.wrap-input {
		position: relative;
	}

	.btn-toggle-pw {
		position: absolute;
		right: 6px;
		bottom: 6px;
		border: none;
		background-color: transparent;
		font-size: 18px;
	}
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

.kare-input.kare-input-pw {
	.form-control.is-invalid {
		background-image: none;
	}

	input {
		padding-right: 44px;
	}
}

// CSS for btn-group
.text-gray-custom {
	color: var(--bs-gray-400);
}

.text-gray-custom:hover {
	z-index: 4;
	color: var(--bs-primary);
	border-color: var(--bs-btn-hover-border-color);
}

.group-button-single-active {
	z-index: 2;
	color: var(--bs-btn-hover-border-color);
	background-color: var(--bs-btn-hover-bg);
	border-color: var(--bs-btn-hover-border-color);
}

// CSS for input react-datepicker libs

.wrap-date-picker {
	border: 1px solid var(--bs-gray-300);
	border-radius: 6px;
	overflow: hidden;
	padding: 6px;

	input {
		border: none;
	}
}

// For custom css for icon
.ti-icon-chevron {
	position: relative;
	top: 2px;
	left: 4px;
}

// input[type="file"] {
// 	display: none;
// }

// Custom CSS for input [type='file']
.custom-file {
	position: relative;
	border: none;

	input {
		padding: 8px 0;
	}

	input[type="file"] {
		border: none;
	}

	input[type="file"]::file-selector-button {
		border: none;
		padding: 0.3em 0.4em;
		border-radius: 0.2em;
		background-color: #539bff;
		transition: 1s;
		position: absolute;
		right: 0;
		top: 12px;
		z-index: 2;
		color: #fff;
	}

	// input[type="file"]::before {
	// 	// content: "Attach Your CV: ";
	// 	color: black;
	// 	margin-right: 10px;
	// }

	.form-control[type="file"]:hover {
		background-color: none;
		color: unset;
	}
}

.custom-file-upload {
	// border: 1px solid #ccc;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}

.input-with-note {
	position: relative;
	width: 44%;
	max-width: 100%;
	input {
		padding: 8px 72px 8px 16px;
	}

	.note {
		position: absolute;
		right: 12px;
		top: 8px;
		font-weight: bold;
	}
}

// CSS for Table Tab

#table-tab {
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: flex-start;

	.wrap {
		padding: 18px;
	}

	.left-side {
		min-height: 400px;
		max-width: 346px;
		width: 100%;
	}

	.right-side {
		min-height: 400px;
		width: 100%;
		border-left: var(--bs-border-width) var(--bs-border-style)
			var(--bs-border-color);
		max-width: calc(100% - 346px);
		// background: var(--bs-cyan);
	}

	.active-btn {
		background-color: var(--bs-btn-hover-bg);
	}
}

// end CSS for Table Tab

// CSS for Sign In page
#sign-in-page {
	min-width: 1000px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	height: 100vh;

	.logo-sign-in {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.left {
		position: relative;
		height: inherit;
		background-color: #f2f6fa;
		width: 50%;
	}

	.right {
		position: relative;
		height: inherit;
		background-color: #fff;
		width: 50%;
	}

	.sign-in-section {
		width: 367px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

// end CSS for Sign In page

// #kare-spinner
#kare-spinner {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	// transform: translate(-50%, -50%);
	width: 100%;
	min-height: 100vh;
	background: rgba(0, 0, 0, 0.7);
	z-index: 2;

	.spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		transform: translate(-50%, -50%);
	}
}

// end #kare-spinner

.application_applied {
	color: #00be77 !important;
	background-color: #e5fefa !important;
}

.application_rejected {
	color: #5a6a85 !important;
	background-color: #eaeff4 !important;
}

.application_approved {
	color: #fff !important;
	background-color: #539bff !important;
}

.contract_in_progress {
	color: #5d87ff !important;
	background-color: #ecf3ff !important;
}

.cancellation_requested {
	color: #f4ad22 !important;
	background-color: #fdf5e5 !important;
}

.cancellation_rejected {
	color: #5a6a85 !important;
	background-color: #eaeff4 !important;
}

.cancellation_completed {
	color: #fff !important;
	background-color: #7c8fac !important;
}

.termination_requested {
	color: #f33030 !important;
	background-color: #fceae7 !important;
}

.termination_rejected {
	color: #5a6a85 !important;
	background-color: #eaeff4 !important;
}

.termination_completed {
	color: #fff !important;
	background-color: #7c8fac !important;
}

.expired {
	color: #f2886c !important;
	background-color: #fbf2ef !important;
}

.customer-badge {
	border-radius: 8px;
	padding: 8px 12px;
}

.w-100px {
	width: 100px !important;
}

.border-radius {
	width: auto;
	display: block;
	border-radius: 12px;
	border: 1px solid #ebf1f6;

	tbody {
		tr:last-child {
			td {
				border-bottom: none;
			}
		}
	}
}

.card-header--no-button {
	min-height: 66px;
}

.card-header {
	padding: 30px;
	background-color: #fff;
	border-bottom: 1px solid #eaeff4;

	&.hide-border {
		border-bottom: 0;
	}

	h4 {
		font-size: 18px !important;
	}
}

.card-body--customer {
	min-height: 460px;
}

.card-body--note {
	min-height: 464px;
}

.py-11px {
	padding-top: 11px;
	padding-bottom: 11px;
}

.h-400px {
	height: 400px;
}

.table--no-border tbody > tr > td {
	border-style: none !important;
}

.man-amount {
	position: absolute;
	right: 50px;
	top: 10px;
	font-weight: 600;
}

.app-badge {
	padding: 8px 16px;
	border-radius: 12px;
	font-size: 12px;
	&.danger {
		color: #ed2224;
		background-color: #fbf2ef;
	}
}

#table-tab .active-btn {
	background-color: #f2f6fa !important;
}

.setting-tab {
	background-color: #fff;
	border-color: transparent !important;
	background-color: transparent !important;
}
